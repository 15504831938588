import { requireService } from '@profgeosoft/di-ez';
import { Table, useGridApiRef } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';

import { wrap } from 'src/packages/mobx-di/wrap';
import { hasValue } from 'src/packages/utils/has-value';
import { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';

import type {
  GridCellEditStartParams,
  GridCellEditStopParams,
  GridCellParams,
  GridColumnResizeParams,
  MuiBaseEvent,
  MuiEvent,
} from '@profgeosoft-ui/react';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';
import type { IControl } from 'src/services/directory-service/types';

import { SERVICE_COLUMNS } from './consts';
import { DirectoryTableMediator } from './directory-table-mediator';
import { tableSx } from './table-sx';
import { useEditableTableColumns } from './use-editable-table-columns';
import { useGetColumnsGrouping } from './use-get-columns-grouping';

import styles from './directory-table.module.scss';

type Props = {
  directory: MainDirectoryService;
};

const MIN_VISIBLE_COLUMNS = 2;

export const EditableDirectoryTable = wrap<Props>(function EditableDirectoryTable({ directory }) {
  const displayManager = directory.tableSettingsManager;
  const notifications = requireService('notificationsService');
  const localization = requireService('localizationService');

  const tableApi = useGridApiRef();
  const columns = useEditableTableColumns(directory);
  const groupingModel = useGetColumnsGrouping(directory.view);

  const handleWidthChange = ({ colDef: { field }, width }: GridColumnResizeParams) => {
    if (!displayManager) return;

    const columns = tableApi.current.getAllColumns();
    const updatedColumnsWidths: Record<string, number> = {};

    columns.forEach((column) => {
      if (column.field === field) {
        updatedColumnsWidths[field] = width;
      } else if (displayManager.settings.columnsWidth && !displayManager.settings.columnsWidth[column.field]) {
        updatedColumnsWidths[column.field] = column.computedWidth;
      }
    });

    displayManager.onWidthChange(updatedColumnsWidths);
  };

  const getCellClassName = useCallback(
    (params: GridCellParams<DirectoryRecord>): string => {
      const control = params.row.controlsMap.get(params.field);

      if (!control) {
        return '';
      }

      const isSelected =
        displayManager?.selectedCellData &&
        displayManager?.selectedCellData.cellField === params.field &&
        displayManager?.selectedCellData.rowId === params.row.recordId;

      return clsx(control.error && 'go-table__error-cell', isSelected && styles.selectedCell);
    },
    [displayManager?.selectedCellData],
  );

  useEffect(() => {
    if (!displayManager || !displayManager.settings?.columnsWidth) {
      return;
    }

    for (const column of tableApi.current.getAllColumns()) {
      if (
        displayManager.settings.columnsWidth[column.field] === column.width ||
        !hasValue(displayManager.settings.columnsWidth[column.field])
      ) {
        continue;
      }

      tableApi.current.setColumnWidth(column.field, displayManager.settings.columnsWidth[column.field]);
    }
  }, [tableApi, columns, displayManager, displayManager?.settings]);

  useEffect(() => {
    directory.setDirectoryViewMediator(new DirectoryTableMediator(directory, tableApi, notifications, localization));
  }, [directory, localization, notifications, tableApi]);

  useEffect(() => {
    let timer: number | undefined;

    const handlePaste = (event: KeyboardEvent) => {
      if (event.code !== 'KeyV' || (!event.ctrlKey && !event.metaKey)) {
        return;
      }

      // игнорируем, если событие реагирует на вставку текста в input поле т.к. в таком случае должна отработать дефолтная логика вставки браузера
      if (event.target instanceof HTMLInputElement) {
        return;
      }

      if (hasValue(timer)) {
        return;
      }

      event.preventDefault();

      directory.directoryViewMediator?.pasteData(displayManager?.selectedCellData || undefined);
      timer = window.setTimeout(() => {
        timer = undefined;
      }, 300);
    };

    document.addEventListener('keydown', handlePaste);

    return () => {
      document.removeEventListener('keydown', handlePaste);
      window.clearTimeout(timer);
    };
  }, [directory.directoryViewMediator, displayManager]);

  useEffect(() => {
    let timer: number | undefined;

    const handleCopy = (event: KeyboardEvent) => {
      if (event.code !== 'KeyC' || (!event.ctrlKey && !event.metaKey)) {
        return;
      }

      if (hasValue(timer)) {
        return;
      }

      const selectedRows = tableApi.current.getSelectedRows();

      if (selectedRows.size < 1) {
        const cellSelection = tableApi.current.unstable_getCellSelectionModel();

        const tableCopyTargetData = (() => {
          if (cellSelection && !!Object.keys(cellSelection).length) {
            return cellSelection;
          }

          if (displayManager?.selectedCellData) {
            return {
              [displayManager.selectedCellData.rowId]: {
                [displayManager.selectedCellData.cellField]: true,
              },
            };
          }

          return null;
        })();

        if (!tableCopyTargetData) {
          return;
        }

        directory.directoryViewMediator?.copySelectedCellData(tableCopyTargetData);

        timer = window.setTimeout(() => {
          timer = undefined;
        }, 500);

        return;
      }

      directory.directoryViewMediator?.copySelectedRowsToClipboard();

      timer = window.setTimeout(() => {
        timer = undefined;
      }, 300);
    };

    document.addEventListener('keydown', handleCopy);

    return () => {
      document.removeEventListener('keydown', handleCopy);
      window.clearTimeout(timer);
    };
  }, [directory.directoryViewMediator, displayManager, tableApi]);

  useEffect(() => {
    const handleFocusIn = ({ field, row }: GridCellParams) => {
      const focusedCellData = { cellField: field, rowId: row.recordId };
      displayManager?.setSelectedCellData(focusedCellData);
    };

    return tableApi.current.subscribeEvent('cellFocusIn', handleFocusIn);
  }, [displayManager, tableApi]);

  const handleCellDeletePress = (params: GridCellEditStartParams, event: MuiEvent<MuiBaseEvent>) => {
    if (params.reason === 'deleteKeyDown') {
      const cellSelectionModel = tableApi.current.unstable_getCellSelectionModel();

      const removeValue = (control: IControl, recordId: number) => {
        directory.onControlValueChange(recordId, control, null);
      };

      const rowIds = Object.keys(cellSelectionModel);

      if (!rowIds.length) {
        return;
      }

      if (rowIds.length > 1 || Object.keys(cellSelectionModel[rowIds[0]]).length) {
        event.defaultMuiPrevented = true;
        const rows: DirectoryRecord[] = [];

        for (const recordId in cellSelectionModel) {
          if (Number.isNaN(Number(recordId))) {
            console.error('record id is not valid');
            continue;
          }

          const row = tableApi.current.getRow(Number(recordId));

          if (!row) {
            continue;
          }

          rows.push(row);

          for (const fieldId in cellSelectionModel[recordId]) {
            const control = row.controlsMap.get(fieldId);

            if (!control) {
              console.error(`cannot find corresponding control for recordId: ${recordId} fieldId: ${fieldId}`);
              continue;
            }

            removeValue(control, Number(recordId));
          }
        }

        tableApi.current.updateRows(rows);
      } else {
        const control = params?.row?.controlsMap.get(params.colDef.field);

        if (!control) {
          return;
        }

        removeValue(control, params.row.recordId);
      }
    }
  };

  const handleComboboxEnterPress = (params: GridCellEditStopParams, event: MuiEvent<MuiBaseEvent>) => {
    if (params.reason === 'enterKeyDown') {
      const control = params?.row?.controlsMap.get(params.colDef.field);

      if (control instanceof MultiComboboxField) {
        event.defaultMuiPrevented = true;
      }
    }
  };

  if (!displayManager) return null;

  return (
    <div className={styles.tableWrapper}>
      <Table
        apiRef={tableApi}
        columns={columns}
        serviceColumns={SERVICE_COLUMNS}
        rows={directory.records}
        sx={tableSx}
        disableColumnReorder={true}
        columnsOrderModel={displayManager.settings.columnsOrderModel}
        onColumnResize={handleWidthChange}
        slotProps={{
          columnMenu: {
            minVisibleColumns: MIN_VISIBLE_COLUMNS,
          },
        }}
        className={styles.table}
        checkboxSelection={true}
        disableAggregation
        disableRowGrouping
        unstable_cellSelection
        loading={directory.isDataFetching || directory.isAdditionalDirectoriesFetching}
        columnGroupingModel={groupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        getRowId={(row) => row.recordId}
        slots={{
          noRowsOverlay: () => null,
        }}
        onRowSelectionModelChange={(model) => directory.directoryViewMediator?.setIsCopyAvailable(!!model.length)}
        onClipboardCopy={(_, event) => {
          event.defaultMuiPrevented = true;
        }}
        onCellKeyDown={(_, event) => {
          if (event.code === 'KeyV' && (event.metaKey || event.ctrlKey)) {
            event.defaultMuiPrevented = true;
          }
        }}
        onCellEditStop={handleComboboxEnterPress}
        onCellEditStart={handleCellDeletePress}
        getCellClassName={getCellClassName}
        valueDragging={true}
        onValueDraggingEnd={directory.directoryViewMediator?.handleValueDraggingEnd}
        getRowClassName={({ id }) => {
          const classNames: string[] = [];

          if (typeof id === 'number') {
            const isActive = directory.recordsActivity[id];

            if (isActive) {
              classNames.push(clsx(styles.disactiveRow));
            }
          }

          return '';
        }}
      />
    </div>
  );
});
