import {
  GRID_ROW_NUMBER_COL_DEF,
  type GridApi,
  type GridCellSelectionModel,
  type GridRowId,
} from '@profgeosoft-ui/react';
import { format, getUnixTime, parse } from 'date-fns';
import { action, makeObservable, observable } from 'mobx';

import { hasValue } from 'src/packages/utils/has-value';
import { CheckboxField } from 'src/services/directory-service/entities/controls/checkbox-field';
import { ComboboxField } from 'src/services/directory-service/entities/controls/combobox-field';
import { DateOnlyPickerField } from 'src/services/directory-service/entities/controls/date-only-picker';
import { LabelField } from 'src/services/directory-service/entities/controls/label-field';
import { MultiComboboxField } from 'src/services/directory-service/entities/controls/multicombobox-field';
import { NumberField } from 'src/services/directory-service/entities/controls/number-field';
import { StringField } from 'src/services/directory-service/entities/controls/string-field';
import { StringsList } from 'src/services/directory-service/entities/controls/strings-list';
import { YearOnlyPickerField } from 'src/services/directory-service/entities/controls/year-only-picker';

import type { TValueDraggingEndEvent } from '@profgeosoft-ui/react';
import type { MutableRefObject } from 'react';
import type { TPrimitive, TPrimitiveArray } from 'src/packages/types';
import type { MainDirectoryService } from 'src/services/directory-service';
import type { DirectoryRecord } from 'src/services/directory-service/entities/directory-record.entity';
import type { IControl, TControlView, TRecord } from 'src/services/directory-service/types';
import type { LocalizationService } from 'src/services/localization-service.ts';
import type { NotificationsService } from 'src/services/notifications-service';

import { ACTIVITY_COLUMN_FIELD, CHECKBOX_SELECTION_COLUMN_FIELD } from './consts';

const NON_DATA_COLUMN_FIELDS = [GRID_ROW_NUMBER_COL_DEF.field, ACTIVITY_COLUMN_FIELD, CHECKBOX_SELECTION_COLUMN_FIELD];

type TCellCoordinates = { cellField: string; rowId: number };
type TCellSelectionModel = Record<number, Record<string, boolean>>;

const convertExcelStringToStringArrays = (text: string): (string | null)[][] => {
  const rawRows = text.replaceAll('\r', '').split('\n');

  const rows: (string | null)[][] = [];

  for (const row of rawRows) {
    const values: (string | null)[] = [];

    for (const value of row.split('\t')) {
      if (value !== '' && value !== '-') {
        values.push(value);
      } else {
        values.push(null);
      }
    }

    rows.push(values);
  }

  return rows;
};

export class DirectoryTableMediator {
  private readonly directory: MainDirectoryService;
  private readonly notifications: NotificationsService;
  private readonly localization: LocalizationService;
  private readonly tableApi: MutableRefObject<GridApi>;

  @observable isCopyAvailable = false;

  constructor(
    directory: MainDirectoryService,
    tableApi: MutableRefObject<GridApi>,
    notifications: NotificationsService,
    localization: LocalizationService,
  ) {
    this.tableApi = tableApi;
    this.directory = directory;
    this.notifications = notifications;
    this.localization = localization;

    makeObservable(this);
  }

  private parseTextToControlValue(
    controlView: TControlView,
    value: string | null,
  ): TPrimitive | TPrimitiveArray | null {
    if (!hasValue(value)) {
      return null;
    }

    switch (controlView.control) {
      case 'CheckBox': {
        const yesInDiffLangs = Object.values(this.localization.getCertainLocaleInAllLanguages('common:yes')).map(
          (val) => val.toLowerCase(),
        );

        return yesInDiffLangs.includes(value.toLowerCase());
      }

      case 'ComboBox': {
        const options = [
          ...(this.directory.optionsService.options[controlView.fieldId] ?? []),
          ...(this.directory.optionsService.archivedOptions[controlView.fieldId] ?? []),
        ];

        const option = options?.find((opt) => opt.label.toLowerCase() === value.toLowerCase());

        return option?.value ?? null;
      }

      case 'MultiComboBox': {
        const options = [
          ...(this.directory.optionsService.options[controlView.fieldId] ?? []),
          ...(this.directory.optionsService.archivedOptions[controlView.fieldId] ?? []),
        ];

        const labels = value.split(',').map((val) => val.trim());
        const values: number[] = [];

        labels.forEach((label) => {
          const correspondingOption = options.find((opt) => opt.label.toLowerCase() === label.toLowerCase());

          if (correspondingOption) {
            values.push(correspondingOption.value);
          }
        });

        return values;
      }

      case 'StringsList': {
        return value.split(',').map((val) => val.trim());
      }

      case 'Field': {
        if (controlView.type === 'String') {
          return value;
        } else {
          const formattedValue = value.replaceAll(/\s+/g, '').replaceAll(/,/g, '.');

          const numberValue = Number(formattedValue);

          return Number.isNaN(numberValue) ? null : numberValue;
        }
      }

      case 'Label': {
        const isSimpleLabel = !('refObjectType' in controlView);

        if (isSimpleLabel) {
          return value;
        }

        const options = [
          ...(this.directory.optionsService.options[controlView.fieldId] ?? []),
          ...(this.directory.optionsService.archivedOptions[controlView.fieldId] ?? []),
        ];

        const option = options?.find((opt) => opt.label.toLowerCase() === value.toLowerCase());

        return option?.value ?? null;
      }

      case 'DateOnlyPicker': {
        const unixValue = getUnixTime(parse(value, 'dd.MM.yyyy', new Date()));

        return Number.isNaN(unixValue) ? null : unixValue;
      }

      case 'YearOnlyPicker': {
        const numberValue = Number(value);

        return Number.isNaN(numberValue) ? null : numberValue;
      }

      default: {
        return null;
      }
    }
  }

  private parseControlValueToText(control: IControl): string {
    if (!hasValue(control.value)) {
      return '-';
    }

    if (control instanceof StringsList) {
      return control.value.join(', ');
    }

    if (control instanceof ComboboxField) {
      const options = [
        ...(this.directory.optionsService.options[control.fieldId] ?? []),
        ...(this.directory.optionsService.archivedOptions[control.fieldId] ?? []),
      ];
      const option = options?.find((opt) => opt.value === control?.value);
      return option?.label ?? '-';
    }

    if (control instanceof MultiComboboxField) {
      const options = [
        ...(this.directory.optionsService.options[control.fieldId] ?? []),
        ...(this.directory.optionsService.archivedOptions[control.fieldId] ?? []),
      ];

      const labels: string[] = [];

      control.value.forEach((value) => {
        const correspondingOption = options.find((opt) => opt.value === value);

        if (correspondingOption) {
          labels.push(correspondingOption.label);
        }
      });

      return labels.join(', ') || '-';
    }

    if (control instanceof CheckboxField) {
      return this.localization.t(`common:${!!control.value ? 'yes' : 'no'}`);
    }

    if (control instanceof LabelField) {
      const controlView = this.directory.controlsViewsMap.get(control.fieldId);

      if (!controlView) {
        console.warn('cannot find corresponding view for given control', control.attrName);

        return '-';
      }

      const isSimpleLabel = !('refObjectType' in controlView);

      if (isSimpleLabel) {
        return control?.value?.toString() ?? '-';
      }

      const options = [
        ...(this.directory.optionsService.options[controlView.fieldId] ?? []),
        ...(this.directory.optionsService.archivedOptions[controlView.fieldId] ?? []),
      ];
      const option = options?.find((opt) => opt.value === control?.value);
      return option?.label ?? '-';
    }

    if (control instanceof DateOnlyPickerField) {
      if (!control.date) {
        return '-';
      }

      return format(control.date, 'dd.MM.yyyy');
    }

    if (control instanceof YearOnlyPickerField) {
      return control.value?.toString() ?? '-';
    }

    if (control instanceof StringField || control instanceof NumberField) {
      if (!hasValue(control.value) || control.value === '') {
        return '-';
      }

      return control.value.toString();
    }

    return '-';
  }

  @action.bound
  async pasteData(tablePasteTarget?: TCellCoordinates): Promise<void> {
    const api = this.tableApi.current;
    let clipboardData = '';

    try {
      clipboardData = await navigator.clipboard.readText();
    } catch (e) {
      console.error(e);
      this.notifications.showErrorMessageT('common:errors.blockedCopyPaste');
      return;
    }

    if (!clipboardData || typeof clipboardData !== 'string') {
      return;
    }

    const columns = api.getVisibleColumns();
    const startPasteColumnIndex = columns.findIndex((col) => col.field === tablePasteTarget?.cellField);
    const visibleColumns = startPasteColumnIndex > 0 ? columns.slice(startPasteColumnIndex) : columns;

    const rowsVisibility = api.state.filter.filteredRowsLookup;

    const filteredColumns = visibleColumns.filter((col) => !NON_DATA_COLUMN_FIELDS.includes(col.field));
    const existingRows = (() => {
      if (!tablePasteTarget) {
        return [];
      }

      const allRows = api.getSortedRows();
      const startPasteRowIndex = allRows.findIndex((row) => row.recordId === tablePasteTarget.rowId);

      if (startPasteRowIndex < 0) {
        return [];
      }

      return allRows.slice(startPasteRowIndex).filter((row) => rowsVisibility[row.recordId]) as DirectoryRecord[];
    })();

    const initialDataRows = convertExcelStringToStringArrays(clipboardData).filter(
      (row) => !row.every((val) => val === null),
    );

    const updatedRows: DirectoryRecord[] = [];
    const newRecordsValues: Partial<TRecord>[] = [];

    initialDataRows.forEach((row, index) => {
      const existingRow = existingRows[index];
      const initialRowValue: Partial<TRecord> = {};

      for (let i = 0; i < row.length; i++) {
        const value = row[i] ?? null;

        const fieldId = filteredColumns[i]?.field;

        if (!fieldId) {
          continue;
        }

        const controlView = this.directory.controlsViewsMap.get(fieldId);

        if (!controlView) {
          continue;
        }

        const parsedValue = this.parseTextToControlValue(controlView, value);

        if (existingRow) {
          const control = existingRow.controlsMap.get(fieldId);

          if (control) {
            this.directory.onControlValueChange(existingRow.recordId, control, parsedValue);
          } else {
            console.warn('corresponding control is not presented for given attrName', fieldId);
          }
        } else {
          initialRowValue[fieldId] = parsedValue;
        }
      }

      if (existingRow) {
        updatedRows.push(existingRow);
      } else {
        newRecordsValues.push(initialRowValue);
      }
    });

    const newRecords = this.directory.createRecords(newRecordsValues);

    api.updateRows([...updatedRows, ...newRecords]);

    const lastNewRecord = newRecords.at(-1);

    if (lastNewRecord) {
      setTimeout(() => {
        api.scrollToIndexes({ rowIndex: api.getRowIndexRelativeToVisibleRows(lastNewRecord.recordId) });
      }, 300);
    }
  }

  @action.bound
  setIsCopyAvailable(isAvailable: boolean): void {
    this.isCopyAvailable = isAvailable;
  }

  copySelectedCellData = async (selectedCellData: TCellSelectionModel) => {
    const api = this.tableApi.current;

    const dataRows: string[] = [];

    for (const rowId in selectedCellData) {
      const row = api.getRow<DirectoryRecord>(rowId);

      if (!row) {
        continue;
      }

      const values: string[] = [];

      for (const fieldId in selectedCellData[rowId]) {
        const control = row?.controlsMap.get(fieldId);

        if (!control) {
          console.warn(`cannot find control for selected cell. rowId: ${rowId}, fieldId: ${fieldId}`);
          values.push('-');
          continue;
        }

        values.push(this.parseControlValueToText(control));
      }

      dataRows.push(values.join('\t'));
    }

    const copyData = dataRows.join('\n');

    try {
      await navigator.clipboard.writeText(copyData);

      api.setRowSelectionModel([]);
      this.notifications.showSuccessMessageT('common:dataCopied');
    } catch (e) {
      console.error(e);
      this.notifications.showErrorMessageT('common:errors.blockedCopyPaste');
    }
  };

  copySelectedRowsToClipboard = async () => {
    const api = this.tableApi.current;
    const columnsOrderModel = this.directory.tableSettingsManager?.settings.columnsOrderModel?.filter(
      (fieldId) => !NON_DATA_COLUMN_FIELDS.includes(fieldId),
    );

    if (!columnsOrderModel) {
      console.error('columnsOrderModel is not presented!');
      return;
    }

    const rowsVisibility = api.state.filter.filteredRowsLookup;
    const columnsVisibility = api.state.columns.columnVisibilityModel;

    const sortedRows = api.getSortedRows();
    const selectedRows = api.getSelectedRows();
    const filteredRows = sortedRows.filter((row) => selectedRows.get(row.recordId) && rowsVisibility[row.recordId]);

    const copyData = filteredRows
      .map((row) => {
        const values: (string | number)[] = [];

        for (const fieldId of columnsOrderModel) {
          if (columnsVisibility[fieldId] === false) {
            continue;
          }

          const control = row.controlsMap.get(fieldId);

          if (!control) {
            console.error(`cannot find corresponding control with field id ${fieldId}. Row: ${row.recordId}`);
            continue;
          }

          values.push(this.parseControlValueToText(control));
        }

        return values.join('\t');
      })
      .join('\n');

    try {
      await navigator.clipboard.writeText(copyData);

      api.setRowSelectionModel([]);
      this.directory.tableSettingsManager?.setSelectedCellData(null);
      this.notifications.showSuccessMessageT('common:dataCopied');
    } catch (e) {
      console.error(e);
      this.notifications.showErrorMessageT('common:errors.blockedCopyPaste');
    }
  };

  @action.bound
  handleValueDraggingEnd(
    model: GridCellSelectionModel,
    [sourceRowId, sourceFieldName]: [GridRowId, string],
    event: TValueDraggingEndEvent,
  ): void {
    event.prevented = true;

    if (typeof sourceRowId !== 'number') {
      return;
    }

    const rowInst = this.directory.recordsMap.get(sourceRowId);

    if (!rowInst) {
      return;
    }

    const cellValue = rowInst.controlsMap.get(sourceFieldName)?.value;

    if (!hasValue(cellValue)) {
      return;
    }

    const rowsToUpdate: DirectoryRecord[] = [];

    for (const rowId in model) {
      const parsedRowId = Number(rowId);
      if (Number.isNaN(parsedRowId)) {
        continue;
      }

      const row = this.directory.recordsMap.get(parsedRowId);

      if (!row) {
        continue;
      }

      rowsToUpdate.push(row);

      for (const fieldId in model[parsedRowId]) {
        const control = row.controlsMap.get(fieldId);

        if (!control) {
          continue;
        }

        this.directory.onControlValueChange(parsedRowId, control, cellValue);
      }
    }

    this.tableApi.current.updateRows(rowsToUpdate);
  }

  onDeleteRecord(record: DirectoryRecord): void {
    const api = this.tableApi.current;
    api.updateRows([{ recordId: record.recordId, _action: 'delete' }]);
  }

  onAddNewRecord(record: DirectoryRecord): void {
    const api = this.tableApi.current;
    const firstEditableCell = api.getAllColumns().find((col) => col.editable);
    api.updateRows([record]);

    if (firstEditableCell) {
      api.setCellFocus(record.recordId, firstEditableCell.field);

      if (firstEditableCell.type !== 'boolean') {
        api.startCellEditMode({ id: record.recordId, field: firstEditableCell.field });
      }
    }

    setTimeout(() => {
      api.scrollToIndexes({ rowIndex: api.getRowIndexRelativeToVisibleRows(record.recordId) });
    }, 300);
  }
}
export { ACTIVITY_COLUMN_FIELD, CHECKBOX_SELECTION_COLUMN_FIELD };
