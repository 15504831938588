import type { TFilterModel, TTablePinModel, TTableSortItem } from './table-settings-service';
import type { TControlView } from '../directory-service/types';

import { HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS } from './table-settings-manager';

type TPinModelCheckResult = {
  isCorrected: boolean;
  model?: TTablePinModel;
};

type TWidthModelCheckResult = {
  isCorrected: boolean;
  model?: Record<string, number>;
};

type TVisibilityModelCheckResult = {
  isCorrected: boolean;
  model?: Record<string, boolean>;
};

type TOrderModelCheckResult = {
  isCorrected: boolean;
  model?: string[];
};

type TSortingModelCheckResult = {
  isCorrected: boolean;
  model?: TTableSortItem[];
};

type TFilteringModelCheckResult = {
  isCorrected: boolean;
  model?: TFilterModel;
};

type TFieldValuesCheckResult = {
  isCorrected: boolean;
  model?: Record<string, unknown>;
};

export const checkAndFixPinModel = (
  columns: Map<string, TControlView>,
  model?: TTablePinModel,
): TPinModelCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: TTablePinModel = {};

  for (const side in model) {
    if (side !== 'left' && side !== 'right') {
      continue;
    }

    const sideModel = model[side];

    if (!Array.isArray(sideModel)) {
      continue;
    }

    const validColumns = [];

    for (const pinnedColumn of sideModel) {
      if (!columns.has(pinnedColumn)) {
        haveModelBeenCorrected = true;
        continue;
      }

      validColumns.push(pinnedColumn);
    }

    correctModel = { ...correctModel, [side]: validColumns };
  }

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};

export const checkAndFixWidthModel = (
  columns: Map<string, TControlView>,
  model?: Record<string, number>,
): TWidthModelCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: Record<string, number> = {};

  for (const column in model) {
    if (!columns.has(column) && !HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS.includes(column)) {
      haveModelBeenCorrected = true;
      continue;
    }

    correctModel[column] = model[column];
  }

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};

export const checkAndFixVisibilityModel = (
  columns: Map<string, TControlView>,
  model?: Record<string, boolean>,
): TVisibilityModelCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: Record<string, boolean> = {};

  for (const column in model) {
    if (!columns.has(column) && !HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS.includes(column)) {
      haveModelBeenCorrected = true;
      continue;
    }

    correctModel[column] = model[column];
  }

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};

export const checkAndFixOrderModel = (columns: Map<string, TControlView>, model?: string[]): TOrderModelCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: string[] = [];

  for (const column of model) {
    if (!columns.has(column)) {
      haveModelBeenCorrected = true;
      continue;
    }

    correctModel.push(column);
  }

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};

export const checkAndFixSortingModel = (
  columns: Map<string, TControlView>,
  model?: TTableSortItem[],
): TSortingModelCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: TTableSortItem[] = [];

  model.forEach((column) => {
    if (columns.has(column.field) && !HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS.includes(column)) {
      correctModel.push(column);
    } else {
      if (!haveModelBeenCorrected) {
        haveModelBeenCorrected = true;
      }
    }
  });

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};

export const checkAndFixFilterModel = (
  columns: Map<string, TControlView>,
  model?: TFilterModel,
): TFilteringModelCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: TFilterModel = { items: [] };

  if (model) {
    model.items.forEach((column) => {
      if (columns.has(column.field) && !HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS.includes(column)) {
        correctModel.items.push(column);
      } else {
        if (!haveModelBeenCorrected) {
          haveModelBeenCorrected = true;
        }
      }
    });
  }

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};

export const checkAndFixFieldValues = (
  columns: Map<string, TControlView>,
  model?: Record<string, unknown>,
): TFieldValuesCheckResult => {
  if (!model) {
    return { isCorrected: false };
  }

  let haveModelBeenCorrected = false;
  let correctModel: Record<string, unknown> = {};

  for (const column in model) {
    if (!columns.has(column) && !HIDDEN_IN_SETTINGS_SIDEBAR_COLUMNS.includes(column)) {
      haveModelBeenCorrected = true;
      continue;
    }

    correctModel[column] = model[column];
  }

  return haveModelBeenCorrected ? { model: correctModel, isCorrected: true } : { isCorrected: false };
};
